<template>
    <div>
        <h1>
            {{ evenement.nom }}
            <text-edit-button @click.native="editNom" v-if="canEdit(evenement)" class="ml-2"/>
        </h1>

        <p>
            Organisé par
            <user-name :user="evenement.organisateur" class="ml-1"/>
            <br/>
            {{ format2Dates(evenement.dateDebut, evenement.dateFin) }}
            <text-edit-button @click.native="editDates" v-if="canEdit(evenement)" class="ml-2"/>
        </p>

        <p v-if="evenement.resume">
            <strong>Résumé : </strong>{{ evenement.resume }}
            <text-edit-button @click.native="editResume" v-if="canEdit(evenement)" class="ml-2"/>
        </p>
        <p v-else-if="canEdit(evenement)">
            <b-btn pill variant="success" v-b-tooltip.hover="'Ajouter un résumé'" @click="editResume" size="sm">
                <font-awesome-icon icon="plus"/>
            </b-btn>
        </p>

        <div v-if="canEdit(evenement)" class="mb-5 mt-3">
            <uploadable-picture :illustrated="evenement" v-model="uploadPicture"
                                max-width="800px" max-height="75vh"
                                type="evenements" class="mw-100"/>
            <p>
                <b-btn variant="success" v-if="uploadPicture" size="sm" @click="sendPicture">
                    Enregistrer l'image
                </b-btn>
                <b-btn variant="danger" v-if="evenement.imageExtension" size="sm" @click="deletePicture">
                    Supprimer l'image
                </b-btn>
            </p>
        </div>
        <b-img :src="image" :alt="evenement.nom" class="picture-event-image mb-5 mt-3 mw-100" v-else/>

        <b-row>
            <b-col xl="8" offset-xl="2" lg="10" offset-lg="1" class="ck-content text-justify mb-3"
                   v-html="sanitize(evenement.description)"/>
            <b-col xl="8" offset-xl="2" lg="10" offset-lg="1" class="text-justify mb-3">
                <text-edit-button @click.native="editDescription" v-if="canEdit(evenement)"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    const UploadablePicture = () => import('@/components/UploadablePicture');
    const EditText          = () => import('@/components/modals/quick_edit/EditText');
    const EditBalloon       = () => import('@/components/modals/quick_edit/EditBalloon');
    const EditTwoDates      = () => import('@/components/modals/quick_edit/EditTwoDates');
    const TextEditButton    = () => import('@/components/text_button/TextEditButton');
    const UserName          = () => import('@/components/UserName');

    import {canEdit}      from '@/util/evenement';
    import {apiPath}      from '@/util/http';
    import alert          from '@/util/alert';
    import {format2Dates} from '@/util/date';
    import {pictureSrc}   from '@/util/image';

    import {sanitize} from 'dompurify';

    export default {
        name: "EvenementInfos",
        components: {UploadablePicture, TextEditButton, UserName},
        props: {
            evenement: {
                type: Object,
                required: true
            },
            editable: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            image() {
                return pictureSrc(this.evenement, 'evenements');
            }
        },
        data: () => ({
            editForm: {},
            uploadPicture: null
        }),
        methods: {
            canEdit(evenement) {
                return this.editable && canEdit(evenement);
            },
            format2Dates,
            sanitize,
            editField(field, component) {
                this.$store.dispatch('modal/create', {
                    component: component,
                    props: {
                        value: this.evenement[field],
                        callback: input => {
                            this.editForm[field] = input;
                            this.submitEditEvenement();
                        }
                    }
                });
            },
            editDates() {
                this.$store.dispatch('modal/create', {
                    component: EditTwoDates,
                    props: {
                        dateDebut: this.evenement.dateDebut,
                        dateFin: this.evenement.dateFin,
                        callback: (dateDebut, dateFin) => {
                            this.editForm.dateDebut = dateDebut;
                            this.editForm.dateFin   = dateFin;
                            this.submitEditEvenement();
                        }
                    }
                });
            },
            editNom() {
                this.editField('nom', EditText);
            },
            editResume() {
                this.editField('resume', EditText);
            },
            editDescription() {
                this.editField('description', EditBalloon);
            },
            submitEditEvenement() {
                alert.loading();
                this.axios.post(apiPath('edit_evenement_infos', {evenement: this.evenement.id}), this.editForm)
                    .then(() => {
                        this.$toaster.success('Événement modifié');
                        this.$emit('edit');
                    })
                    .catch(() => this.$toaster.error("Impossible de modifier l'événement"))
                    .finally(alert.stopLoading);
            },
            sendPicture() {
                alert.loading();
                let formData = new FormData();
                formData.append('picture', this.uploadPicture);
                this.axios
                    .post(
                        apiPath('edit_evenement_picture', {evenement: this.evenement.id}),
                        formData,
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(() => {
                        this.uploadPicture = null;
                        this.$toaster.success(
                            "Image modifiée (si la modification n'est pas visible, forcez le rechargement en appuyant sur Ctrl + Maj + R ou Ctrl + F5)",
                            {timeout: 10000}
                        );
                        this.$emit('edit');
                    })
                    .catch(() => this.$toaster.error("Impossible d'enregistrer l'image"))
                    .finally(alert.stopLoading);
            },
            deletePicture() {
                alert.loading();
                this.axios.delete(apiPath('delete_evenement_picture', {evenement: this.evenement.id}))
                    .then(() => {
                        this.uploadPicture = null;
                        this.$toaster.success('Image supprimée');
                        this.$emit('edit');
                    })
                    .catch(() => this.$toaster.error("Impossible de supprimer l'image"))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.editForm = {...this.evenement};
        }
    }
</script>

<style scoped>
    img.picture-event-image {
        max-height: 75vh;
        object-fit: contain;
    }
</style>